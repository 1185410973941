import type { UserAttributes, Provider } from "@supabase/supabase-js";

export const useAuthStore = defineStore("auth", () => {
  const config = useRuntimeConfig();
  const supabase = useSupabaseClient();

  const signIn = async (email: string, password: string) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });

    let errorMessage = error?.message;

    if (!error && !data?.user?.user_metadata.is_employer) {
      await supabase.auth.signOut();
      errorMessage = ERROR_MESSAGES.has_app_account;
    }

    return { errorMessage };
  };

  const signUp = async (email: string, password: string) => {
    const { error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        emailRedirectTo: `${config.public.appUrl}${API_ROUTES.authCallback}?type=signup`,
        data: {
          has_password: true,
          is_employer: true,
          new_owner: true,
        },
      },
    });
    if (error) throw createError({ statusMessage: error.message ?? error });
  };

  const signInWithOAuth = async (provider: Provider) => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: `${config.public.appUrl}${API_ROUTES.authCallback}?type=oauthSignIn`,
      },
    });
    return { error };
  };

  const createMemberWithOauth = async (provider: Provider) => {
    const { error } = await supabase.auth.linkIdentity({
      provider: provider,
      options: {
        redirectTo: `${config.public.appUrl}${API_ROUTES.authCallback}?type=oauthMemberCreate&oauthIdentity=${provider}`,
      },
    });
    return { error };
  };

  const signOut = async () => {
    await supabase.removeAllChannels();
    const { error } = await supabase.auth.signOut();
    resetStores();
    return { error };
  };

  const requestNewPassword = async (email: string) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${config.public.appUrl}${API_ROUTES.authCallback}?type=recovery`,
    });
    return { error };
  };

  const updatePassword = async (currentPassword: string, password: string) => {
    await verifyPassword(currentPassword);
    const { error } = await updateUser({ data: { has_password: true }, password });
    if (error) return { error };
    return { error: null };
  };

  const updateEmail = async (currentPassword: string, email: string) => {
    await verifyPassword(currentPassword);
    const { error } = await updateUser(
      { email },
      {
        emailRedirectTo: `${config.public.appUrl}${API_ROUTES.authCallback}?type=email_changed`,
      }
    );
    if (error) return { error };
    return { error: null };
  };

  const updateUser = async (userData: UserAttributes, options?: any) => {
    const { error } = await supabase.auth.updateUser(userData, options);
    return { error };
  };

  const verifyPassword = async (password: string) => {
    const verified = await $api<boolean>(API_ROUTES.verifyPassword, {
      method: "post",
      headers: useRequestHeaders(["cookie"]),
      body: { password },
    });
    if (!verified) throw createError({ statusMessage: ERROR_MESSAGES.current_password_incorrect });
    return verified;
  };
  return {
    signIn,
    signUp,
    signOut,
    requestNewPassword,
    updateUser,
    updatePassword,
    updateEmail,
    verifyPassword,
    signInWithOAuth,
    createMemberWithOauth,
  };
});
